button {
    cursor: pointer;
}


.navbar-brand img {
    width: 210px;
    height: 34px;
}



.personal-box button,
.creat-btn button {
    padding: 7px 30px;
    border-radius: 30px;
    color: var(--white);
    background-color: #16d489;
    border: none;
    display: block;
    margin: auto;
}

.videoflix-btn button {
    background-color: #16d489;
    border: none;
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spin {
    animation: spin 1000ms linear infinite;
}

.update button,
.btn-submit {
    padding: 7px 30px;
    border-radius: 30px;
    color: var(--white);
    background-color: #16d489;
    border: none;
    display: block;
}

.custom-file-input {
    position: absolute;
    z-index: 2;
    width: 10%;
    height: calc(2.25rem + 33px);
    margin: 0;
    opacity: 0;
    left: 34%;
    cursor: pointer;
}

a.btn-submit:hover {
    text-decoration: none;
}

.d-block {
    display: block !important;
}

.hide-button {
    pointer-events: none;
}

.modal {
    /*display: block !important;*/
    background: rgba(0, 0, 0, .2);
}

.modal-header .close {
    margin: 0 !important;
    padding: 0 !important;
    position: absolute !important;
    right: 20px !important;
}

.modal-header {
    border: none !important;
}

.modal-footer {
    border: none !important;
}

.modal-footer button {
    padding: 10px 30px;
    background-color: #16d489;
    color: #fff;
    border: none !important;
}

.modal-body select {
    height: 50px !important;
}

.modal-body select:focus {
    box-shadow: none !important;
}

.modal-content {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 22%);
}

.icon-pop .form-check-input {
    height: 70px;
    width: 70px;
    position: absolute;
    left: -16%;
    top: -166%;
    opacity: 0;
    cursor: pointer;
}

.custom-span {
    position: absolute;
    top: 80px;
    left: 0;
}

.custom-eye {
    margin-right: 10px;
}

.check-span {
    display: none;
    position: absolute;
    left: 50%;
    top: 32%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #16d489;
    border-radius: 50%;
    color: #fff;
}

.icon-pop .form-check {
    position: absolute;
    display: block;
    padding-left: 1.25rem;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.show-span {
    display: inline-block;
}

.hide-txt {
    display: none;
}

/*.schedule-icon ul li:hover {*/
/*    transform: scale(1.5);*/
/*    transition: .4s ease-in-out;*/
/*}*/

/*.schedule-icon ul li{*/
/*    transition: .4s ease-in-out;*/
/*}*/

/* Fullcalendar Css */
.fc-day-past {
    background: #d2d6da33 !important;
}

.fc-day-future,
.fc-day-today {
    cursor: pointer;
}

.schedule-icon li a img {
    margin-left: 5px;
}

.list-inline li a img {
    margin-left: 0px;
}

#file1 {
    opacity: 0;
    position: absolute;
    width: 70px;
    height: 75px;
    cursor: pointer;
}

/** Date picker */
.react-datepicker-wrapper {
    width: 100%;
    margin-bottom: 10px;
}

.react-datepicker-wrapper input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
}

.fc-button-primary {
    text-transform: capitalize !important;
}

.hide-social {
    display: none !important;
}

.display-div {
    display: block !important;
}

.left-0 {
    left: 0 !important;
}

.emoji-custom {
    display: inline-flex;
    font-size: 40px;
    cursor: pointer;
    margin: 10px 5px;
}

.creator-icon a {
    cursor: pointer;
}

.creator-download a {
    cursor: pointer;
}

.subhead {
    font-size: 30px !important;
    font-weight: 400 !important;
}

.txtbody {
    font-size: 20px !important;
    font-weight: normal !important;
}

.text-items a {
    cursor: pointer;
}

.overflow-hide {
    overflow-x: auto;
}

.creator-icon #mainTooltip {
    width: 130px !important;
    text-align: center !important;
}

#mainTooltip {
    width: 200px !important;
}

.red-tooltip+.tooltip>.tooltip-inner {
    background-color: #000000;
}


/*Video CSS*/
.logo-top-left {
    position: absolute;
    left: 0;
    top: 0;
    text-align: left;
}

.logo-top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.logo-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0%, 0%);
}

.logo-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.top-left {
    position: absolute;
    left: 0;
    top: 0;
    text-align: left;
}

.top-center {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}

.top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.center-left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);

}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center-right {
    position: absolute;
    top: 50%;
    right: 0;
}

.bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0%, 0%);
}

.bottom-center {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}

.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.box.bottom_right {
    border-bottom-right-radius: 15px;
}


.list-vidoes {
    position: relative;
}


.list-vidoes .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 20%;
    opacity: 0;
    transition: .3s ease;
    background-color: #16d489;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


.list-video {
    width: auto;
    margin: 5px;
}

.list-vidoes:hover .overlay {
    opacity: 1;
}


.list-vidoes .icon:nth-child(1) {
    color: white;
    font-size: 14px;
    position: absolute;
    top: 7%;
    left: 30%;
}



.list-vidoes .icon:nth-child(2) {
    color: white;
    font-size: 14px;
    position: absolute;
    top: 72%;
    left: 30%;
}

.list-vidoes .tilesvideosize img {
    height: 100px;
}

.list-vidoes .tilesvideosize {
    width: 150px !important;
    height: 100px !important;
}

.join-vidoes .play-icon {
    height: 300px;
}

/* .join-vidoes .play-icon img{
    width: 100%;
    height: 100%;
} */

.list-vidoes .fa-user:hover {
    color: #eee;
}

.genrate-play-save button {
    padding: 5px 7px;
    border: 1px solid #000;
    border-radius: 50px;
    font-size: 13px;
    color: #000 !important;
    background-color: transparent !important;

}

.genrate-play-save button:focus {
    outline: none !important;
}

.create-video-sec .topsize {
    margin-top: -20px;
}

.create-video-sec .col-md-8 .linebar {
    position: relative;
}

.create-video-sec .col-md-8 .linebar::before {
    position: absolute;
    content: '';
    width: 5px;
    height: 40px;
    background-color: #16d489;
    top: 10px;
    left: 0;
}

.upload-border .custom-file-input-audio {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 21vh;
    margin: 0;
    left: -1%;
    opacity: 0;
    cursor: pointer;

    top: -38%;
}

.social-success {
    color: green !important;
}

#v-pills-audiolibrary .infinite-scroll-component {
    display: unset !important;
    flex-wrap: wrap !important;
}


.clientcard-box {
    height: 300px;
    display: flex;
    align-items: center;
    background-color: transparent !important;
    border: 1px solid #000;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    position: relative;
    z-index: -1 !important;
}


.social-failed {
    color: #ff5050 !important;
}

.infinite-scroll-component {
    display: flex !important;
    flex-wrap: wrap !important;
}


.blur-image {
    filter: blur(4px);
    transition: 0.1s all;
}

.text-pos-active {
    background-color: #16d489 !important;
}

.swatch {
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
    width: 100%;
    padding: 10px;
}

.swatch-color {
    width: 100%;
    height: 14px;
    border-radius: 2px;
}

.swatch-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.active-tile {
    border: 3px solid #16d489;
    background-color: #16d489;
    border-radius: 5px;
    width: auto;
}

.icon-space {
    margin-left: 5px;
}

.trands-sec .css-g1d714-ValueContainer,
.trands-sec .css-2b097c-container {
    width: 250px !important;
}

.css-2b097c-container .css-g1d714-ValueContainer {
    width: 100% !important;
}


.trands-table-box table {
    border-radius: 0px !important;
}

/*Scheduler*/

/* .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    position: relative;
    min-height: 2em;
    z-index: 1;
    transition: .2s ease-in-out;
    margin:  auto !important;
} */

.fa-custom {
    position: absolute;
    bottom: -170%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    display: none;
}

.fc-daygrid-day-number {
    width: 100%;
    text-align: end;
}

.fc-day-today:hover .fa-custom,
.fc-day-future:hover .fa-custom {
    display: block;
}

.pop-up-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    margin: auto;
}

.image-editor .sketch-picker {
    position: absolute;
    left: -75px;
    top: 47px;
    z-index: 999;
}

.image-editor-2 .sketch-picker {
    position: absolute;
    left: 6px;
    top: 47px;
    z-index: 999;
}





.youtube-content h5 {
    /* text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical; */
    font-size: 14px !important;
}

.youtube-content span {
    font-size: 13px;
}

.youtube-content span a {
    text-decoration: none;
}

.youtube-content p {
    font-size: 12px;
    margin-top: 10px;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 3;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    display: -webkit-inline-box;
    font-weight: 400;
}

/* .view-visit-download ul li+li {
    margin-left: 25px;
} */

.view-visit-download ul li a {
    border: 1px solid rgb(138 137 137);
    border-radius: 5px;
    background-color: #fff;
    transition: .3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    color: rgb(33 36 41);
    padding: 4px 6px;
    font-size: 10px;
    border-radius: 30px;
}

.view-visit-download ul .votesonly a {
    background-color: transparent !important;
    border: none !important;
    padding: 0px !important;
    font-size: 12px;

}

.view-visit-download ul li a:hover {
    background-color: var(--primary-color);
    transition: .3s ease-in-out;
    color: #fff !important;
}

.view-visit-download ul li a span {
    margin-right: 2px;
}

.youtube-content {
    padding: 10px;
    background-color: #eee;
}



.view-like-share a {
    text-decoration: none;
    font-size: 10px;
}



.upload-box-video .img-box span {
    display: none !important;
    transition: .3s ease-in-out !important;
}

.upload-box-video .img-box span.checked-icon {
    display: block !important;
}

.upload-box-video .img-box ul li:hover span.delete-icon {
    display: block !important;
    transition: .3s ease-in-out !important;
}

.view-like-share-count {
    font-size: 10px;
    text-decoration: none;
}

.view-like-share span {
    margin-right: 3px;
}

/*Time Keeper*/
.css-nakgy8-TimeKeeper,
.react-timekeeper {
    position: absolute !important;
    z-index: 999;
}

.css-1mn38ah-TopBar,
.react-timekeeper__top-bar {
    background-color: #16d489 !important;
}


.navbar-active-color {
    background-color: #16d489;
    width: 100px;
    height: 2px;
}

.react-timekeeper__tb-hour,
.react-timekeeper__tb-colon,
.react-timekeeper__tb-minute {
    color: #ffffff !important;

}


.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
    padding: 1px;
    text-align: center !important;
}


.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 2px;
    text-align: center !important;
}



.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {

    /* min-height: 2em; */
    overflow: hidden;
    width: 150px;
    height: 98px;
    transition: 2s ease-in-out !important;
    margin: auto !important;
}


.cap-type {
    text-transform: capitalize;
}


.premade-box {
    background-color: #bec4ca;
    padding-bottom: 20px;
    border-radius: 10px;
}

.premade-body {
    padding: 10px;
}

.premade-body a {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.premade-box .premade-header {
    background-color: var(--primary-color);
    padding: 10px;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


/* .fc .fc-daygrid-body-unbalanced  .fc-daygrid-day-events:hover {
    display: block !important;
    height: auto !important;
    transition: .5s ease-in-out !important;
} */


.fc-daygrid-day-frame.fc-scrollgrid-sync-inner:hover .fc-daygrid-day-events {
    display: block !important;
    height: auto !important;
    transition: 2s ease-in-out !important;
}


.welcome-box .welcome-head {
    padding: 10px 20px;
}

.upload-font {
    font-size: 12px !important;
}

.cursor-pointer {
    cursor: pointer;
}



/* width */
.plus-icon-scrollbar ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

/* Track */
.plus-icon-scrollbar ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.plus-icon-scrollbar ::-webkit-scrollbar-thumb {
    background: #555;

}

/* Handle on hover */
.plus-icon-scrollbar ::-webkit-scrollbar-thumb:hover {
    background: #16d489;
}

.opacity-button {
    opacity: .5;
}


.youtube-desc {
    overflow: hidden;
}

.quote-button {
    background-color: #bec4ca;
    border-radius: 30px;
    border: none;
    color: #fff;
    font-size: 12px;
    padding: 0 8px;
    transition: .2s ease-in-out;
}

.quote-button:hover {
    background-color: #093470 !important;
    transition: .2s ease-in-out !important;
}

/* .navbar ul li a.active-menu{
    color: #16d489 !important;
} */

.deshborad-sec-2-head a {
    color: #000;
    transition: .3s ease-in-out;
}

.deshborad-sec-2-head a:hover {
    color: #16d489 !important;
    transition: .3s ease-in-out;
}

.navbar ul li a.active-menu {
    color: #646464 !important;
    background: #F1F1F1;
    border-radius: 30px;
    padding: 4px;
}

.show-google button:focus {
    box-shadow: none;
}


/* width */
.imgcreator-sec .nav-tabs::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}

/* Track */
.imgcreator-sec ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.imgcreator-sec ::-webkit-scrollbar-thumb {
    background-color: #093470 !important;
}

.imgcreator-sec .nav-tabs ::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-color);
}

.my-search-table-iframe .ibox-content {
    overflow-x: auto;
}

.my-search-table-iframe .img-content table {
    min-width: 600px !important;
}

/* .col-lg-8-custome {
    width: 61.666667%;
} */

.creator .canvas-container {
    width: 100% !important;
}

.my-table-wrap {
    overflow-x: auto;
}

.my-table-wrap table {
    min-width: 500px;
}

.social-icon-share button svg {
    width: 20px !important;
    margin-right: 5px;
}

.css-1okebmr-indicatorSeparator {
    display: none !important;
}

.css-tlfecz-indicatorContainer {
    display: none !important;
}

.css-1wa3eu0-placeholder {
    display: none;
}

.css-1wy0on6 {
    display: none !important;
}


.infinite-scroll-component .align-loader {
    display: unset !important;
}

.show-google-toggle:focus {
    box-shadow: none;
}

.sweet-alert .btn-danger {
    background: rgb(212, 63, 58) !important;
}

.sweet-alert .btn-light {
    background: #f8f9fa !important;
}

.yt-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.active-edit {
    background: #093470;
    color: #ffffff;
}


.twitter-social-bgcolor {
    background-color: #1DA1F2 !important;
}

.youtube-social-bgcolor {
    background-color: #FF0000 !important;
}

.facebook-social-bgcolor {
    background-color: #4267B2 !important;
}

.reddit-social-bgcolor {
    background-color: #FF5700 !important;
}

.linkdin-social-bgcolor {
    background-color: #0077b5 !important;
}

.vimeo-social-bgcolor {
    background-color: #86c9ef !important;
}

.telegram-social-bgcolor {
    background-color: #0088cc !important;
}

.wordpress-social-bgcolor {
    background-color: #21759b !important;
}


.medium-social-bgcolor {
    background-color: #000000 !important;
}

.uc-first {
    text-transform: capitalize;
}

.custom-training {
    background: #edecec;
    padding: 40px;
    color: #000000;
}

.custom-training h4 {
    color: #000000 !important;
}

.custom-training p {
    color: #000000 !important;
}

.agency-btn {
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 210px;
}

.agency-btn:hover {
    color: #ffffff;
}

.my-img {
    position: relative;
}

.my-img .custom-file-input {
    position: absolute;
    z-index: 2;
    width: 84%;
    height: calc(2.25rem + 33px);
    margin: 0;
    opacity: 0;
    left: -5%;
    cursor: pointer;
    background: red;
    font-size: 1px;
    border-radius: 50%;
}

.fc .fc-daygrid-day.fc-day-today:hover .fa-custom {
    bottom: -60px;
}


.fc-daygrid-day.fc-day.fc-day-future:hover .fa-custom {
    bottom: -60px;
}

.rdw-editor-toolbar {
    margin-bottom: 0 !important;
}

.rdw-editor-main {
    background: #ffffff !important;
    border: 1px solid rgba(0, 0, 0, .1);
}

.my-account-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #16d489;
}

.deshborad-sec-2-head a {
    color: #000;
    transition: .3s ease-in-out;
}

.deshborad-sec-2-head a:hover {
    color: #16d489 !important;
    transition: .3s ease-in-out;
}

.rv-anchor {
    text-decoration: none;
}

.trending-details-single {
    border-radius: 30px;
    background: var(--white);
    padding: 30px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.trending-left {
    width: 154px;
}

.trending-img {
    width: 154px;
    height: 200px;
    border-radius: 12px;
    overflow: hidden;
}

.trending-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.trending-right {
    width: calc(100% - 154px);
    padding-left: 30px;
}

.trending-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.bottom-btn-group {
    text-align: right;
    padding-top: 50px;

}

.bottom-btn-group button {
    background-color: var(--primary-color);
    color: #fff;
    padding: 7px 30px;
    border-radius: 30px;
    border: none;
}