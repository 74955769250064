@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&display=swap');


:root{
    --primary-color:#093470;
    --secondary-color:#3e99f3;
    --headcolor:#384653;
    --white:#ffffff;
    --fz60:60px;
    --fz58:58px;
    --fz56:56px;
    --fz54:54px;
    --fz52:52px;
    --fz50: 50px;
    --fz48: 48px;
    --fz46: 46px;
    --fz44: 44px;
    --fz42: 42px;
    --fz40: 40px;
    --fz38: 38px;
    --fz36: 36px;
    --fz34: 34px;
    --fz32: 32px;
    --fz30: 30px;
    --fz28: 28px;
    --fz26: 26px;
    --fz24: 24px;
    --fz22: 22px;
    --fz20: 20px;
    --fz18: 18px;
    --fz16: 16px;
   --fz14:14px;
    --fw4:400;
    --fw5:500;
    --fw6:600;
    --fw7:700;
    --fw8:800;

    --Poppins:'Poppins', sans-serif;

}

body{
    font-size: var(--fz16) !important ;
    font-weight: var(--fw4) !important;
    line-height: 1.5 !important;
    font-family: var(--Poppins) !important;
}

.wrapper-box{
    min-height: 100vh !important;
}


.navbar{
    background:url('../src/images/BG.png');
    height: 60px;
    transition: .3s ease-in-out;
    background-size: cover;
    background-position: center;
}

.fixed-me {
    background-color: var(--primary-color); 
    position: fixed;
    top: 0;
    z-index: 8888;
    width: 100%;
    height: 60px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
    transition: .3s ease-in-out;
   
}


.navbar ul li a{
    font-size: var(--fz16) !important;
    color:#057EDD !important;
    transition: .3s ease-in-out;
}

/* .navbar ul li a:hover{
    color:#16d489 !important;
    transition: .3s ease-in-out;
} */

.navbar ul li .dropdown-menu a{
    color: #000000 !important;
}

.navbar ul li .dropdown-menu a:hover{
    color:var(--white) !important;
}

.navbar ul li {
    margin-right: 6px;
    display: flex;
    align-items: center;
}

.navbar ul li:nth-last-child(1) a img{
      border-radius: 100%;
      max-width: 40px;
}

.belldropdown .dropdown-item {
    white-space: normal !important;
    background-color: #efeeee94;
    margin: 5px 0px;
    font-size: 14px;
}
.belldropdown .dropdown-menu{
    left: -131px !important;
    position: absolute;
}

.navmenuid .dropdown-menu{
    left: -120px !important;
    position: absolute;
}



.sec-1{
    padding: 0;
    background-color: #f1f0f0;
}

.sec-1 .sec-1-heading ul li a{
   color:var(--headcolor);
   text-decoration: none;
}

button#dropdownMenuButton {
    background: transparent;
    border: none;
    padding: 0px;
}
button#dropdownMenuButton:focus{
    box-shadow: none !important;
    border-color: transparent !important;
    outline: unset !important;
}

button#dropdownMenuButton img {
    
    height: 100%;
    border-radius: 50%;
}

.belldropdown button{
    color:#fff;
}

.navbar-nav .dropdown-menu{
    position: absolute;
    left: 0px ;
    top: 44px;
}


.navbar-nav .navdropdown {
    position: absolute;
    left: -10px;
    top: 54px;
}

.dropdown-toggle::after{
    display: none !important;
}


.sec-1 .sec-1-heading li{
    position: relative;
}

.sec-1-heading li:nth-child(1)::before {
    content: '';
    position: absolute;
    border-right: 1px solid #ffffff;
    width: 2px;
    height: calc(100% + 122%);
    top: -15px;
    right: -24px;
}

.form-card{
    padding: 40px 0px;
}

.form-card form {
    background-color: #ececec;
    padding: 44px 35px;
    border-radius: 10px;
}

.form-card form .user-img img {
    max-width: 80px;
    border-radius: 50%;
    height: 80px;
}

.user-img {
    display: flex;
    justify-content: center;
}

.tbody-bg .td-br h6{
    text-overflow: ellipsis;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;

}



.deshborad-sec .reports h6{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
    margin: auto;
}



.form-card form  .form-control {
    border: none;
    width: 100%;
    background: transparent;
    padding: 0px;
}

.form-card form .form-group {
    padding: 5px 15px;
    margin-bottom: 1rem;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: left;
}
.form-card form  input:focus{
    box-shadow: none !important;
}

.form-card .Desciption{
    height: 40px;
}

.form-group ::placeholder {
    color: #bfc6cc !important;
    opacity: 1; /* Firefox */
  }


  /*
.form-card .text-muted {
    color: #212529 !important;
    font-weight: 400;
}*/


.form-card form .creat-btn a{
      padding: 8px 25px;
      border-radius: 30px;
      background-color:#16d489 !important;
      color:var(--white);
      text-decoration: none !important;
}
.form-card  textarea:focus{
    box-shadow: none !important;
}



.button {
    display: inline-block;
    padding: 0.75rem 1.25rem;
    border-radius: 10rem;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 10rem;
    z-index: -2;
}
.button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--primary-color);
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
}
.button:hover {
    color: #fff;
}
.button:hover:before {
    width: 100%;
}




.user-data {
    background-color: #ececec;
    padding: 55px 30px;
    border-radius: 10px;
    text-align: left;
}

.user-data .table{
   max-width: 100% !important;
   overflow-x: auto !important;
}

.user-data ul li img{
    max-width: 80px;
    transition: all .4s;
}

.user-data ul li a img:hover{
    transform: scale(0.9);
    transition: all .4s;

}

.user-data .theadbgcolor{
    background-color: var(--secondary-color);
}


.tranding-post-sec .theadbgcolor{
    background-color: var(--secondary-color);
    color:#fff;
}

.table{
    background-color: #f7f8fc;
    border-top-left-radius:5px !important;
}


.table thead th {
    border-bottom: 0 solid #dee2e6!important;
    padding: 15px 4px;
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    vertical-align: middle;
}

.user-data span {
    font-size: var(--fz16);
    font-weight: var(--fw4);
}
.footer-menu ul li a {
    color: var(--white);
    font-size: 14px;
    text-decoration: none;
    transition: .3s ease-in-out;
}
.footer-menu ul li a:hover{
  color:#16d489 !important;
  transition: .3s ease-in-out;

}
.footer-copyright span a{
    transition: .3s ease-in-out;
    color: #16d489;
    text-decoration: none;
}
/* .schedule-table table tr, .table-hover tr{
    text-align: center;
} */
 canvas{
    width: 100% !important;
 }
.footer{
    background-color: var(--primary-color);
    padding: 10px;
}

.footer-copyright span {
    font-size: 12px;
}

.form-row .input-group {
    flex-wrap: nowrap !important;
    display: inline-flex !important;
    background: #ffffff !important;
    height: 70px !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.form-row span#basic-addon1 {
    background: transparent;
    border: none;
    border-right: 1px solid #ececec;
    padding: 5px;
    color:#bfc6cc;
}


/* deshborad css start */

.deshborad-banner-sec{
    background:#1170D1;

    width:100%;
}


.client-arrow{
    width: 80%;
    background: #0c4fae;
    padding: 10px 30px;
    border-radius: 50px;
    margin: auto;
    cursor: pointer;
   
}

.marks-client{
    position: relative;
}

.marks-client::after {
    content: url('../src/images/ICON\ MASK.png');
    position: absolute;
    top: -14px;
    left: 100px;
    width: 100px;
}

.marks-stream{
    position: relative;
}

.marks-stream::after {
    content: url('../src/images/ICON\ MASK2.png');
    position: absolute;
    top: -1px;
    left: 240px;
    width: 100px;
}


.marks-schedule{
    position: relative;
}

.marks-schedule::after {
    content: url('../src/images/ICON\ MASK3.png');
    position: absolute;
    top: 0px;
    left: 100px;
    width: 100px;
}


.marks-created{
    position: relative;
}
.marks-created::after {
    content: url('../src/images/ICON\ MASK4.png');
    position: absolute;
    top: -9px;
    left: 244px;
    width: 100px;
}

.marks-earned{
    position: relative;
}
.marks-earned::after {
    content: url('../src/images/Mask\ Group\ 1.png');
    position: absolute;
    top: -23px;
    left: 100px;
    width: 100px;
}


.deshborad-banner-content h5 {
    font-size: 30px;
}
.deshborad-banner-content p {
    font-size: 16px;
    font-weight: 400;
    width: 80%;
}
.deshborad-banner-content h2 {
    font-size: 50px;
    font-weight: 700;
    position: relative;
}

/* .deshborad-banner-content h2::before{
    content: 'AGENCYREEL 2.0';
    position: absolute;
    top:0;
    left: 0;
    font-size: 50px;
    font-weight: 700;

} */
/*
.deshborad-banner-content h2::before {
    content: 'AGENCYREEL 2.0';
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 50px;
    font-weight: 700;
    opacity: 0.1;

} */

.num h6 span {
    font-size: 30px;
    font-weight: 600;
    margin-right: 10px;
}

.num h6 {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.deshborad-banner-content .watch-now a{
    padding: 10px 20px;
    background-color: #16d489;
    border-radius: 30px;
    color:var(--white);
    text-decoration: none;
}
.deshborad-banner-content .watch-now a span{
    margin-right: 5px;
    color:var(--white);
}

.youtube-content h6 #mainTooltip{
    width: 170px !important;
}
.sign-now a:hover {
    color: #fff !important;
}


.deshborad-banner-content.text-white {
    padding: 150px 0px;
}

.deshborad-conunt {
    padding-top: 90px;
    padding-bottom: 40px;
}

.arrow span {
    color: #16d489;
    font-size: var(--fz20);
}

.deshborad-sec {
    padding: 60px 0px 60px;
}

.newbanner-line .bannerline{
    padding: 5px;
    background-color: #00D3FF;
}

.newbanner-line .bannerline-second{
    padding: 5px;
    background-color:#16D489;
}

.deshborad-box{
    position: relative;
    width: 100%;

    background-color: var(--secondary-color);
    border-radius:20px;
    overflow: hidden;
  }

  .newbanner-line {
    overflow: hidden;
}

  .belldropdown button {
    background: linear-gradient(to right , #01D084 , #0482D8) !important;
    padding: 7px 10px !important;
    border-radius: 30px;
    display: inline-block;
}

  .deshborad-box .image {
    display: block;
    width: 100%;
    height: auto;
  }


.dropdown-item {
    display: block ;
    width: 100%;
    font-size: 14px !important;
    padding: 10px 15px !important;
    clear: both;
    font-weight: 400;
    color: #212529 !important;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent !important;
    border: 0;
}


.dropdown-item:focus, .dropdown-item:hover{
    background-color: var(--primary-color) !important;
    color:var(--white)  !important ;
}
.deshborad-box{
    position: relative;
}

  .deshborad-box .overlay {
    position: absolute;
    /* bottom: 100%; */
    left: 50%;
    /* right: 0%; */
    top: 0%;
    background-color: var(--primary-color);
    overflow: hidden;
    width: 100%;
    height:0;
    transition: .5s ease;
    border-radius: 20px;
    transform: translate(-50%,-50%);
    /* box-shadow:0px 0px 41px rgb(0 0 0 / 35%); */
  }
.deshborad-box:hover .overlay {
    /* bottom: 0px; */
    top: 0%;
    height: 100%;
  }
  .mydeshborad-box:hover .overlay{
    top: 50%;
  }
  .dashboard-custom .deshborad-box:hover .overlay{
    border-radius: 50%;
    height: 200px;
    width: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    /* transform: translate(-50%,-50%); */
  }
  .dashboard-custom .deshborad-box > img{
width: 200px !important;
height: 200px;
border-radius: 50%;
  }
  .deshborad-box .icon {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
  }
  .deshborad-box .icon a{
      text-decoration: none;
  }






  .deshborad-box .icon ul li span{
      background-color: var(--secondary-color);
      width: 50px;
      height: 50px;
      border-radius: 50px;
      display: inline-block;
      line-height: 50px;
      text-align: center;
      font-size: 14px;
  }
 .deshborad-box  .icon ul li h6{
      font-size: 12px;
      color:var(--white);
      margin-top: 10px;
  }
 .deshborad-box .icon ul li{
      margin: 5px;
  }

  .project-data{
      background-color: #fff;
      border-radius: 30px;
      margin-top: 20px;
      padding: 10px;
      border: 1px solid #000;
  }

  .project-data ul li {
    color: #000;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
  .join-sec {
    padding: 60px 0px 60px;
    background:linear-gradient(to right,#00AEDB ,#3ECB92);

}
  .join-sec .join-sec-content h4{
      color: #fff;
      font-size: var(--fz28);
      font-weight: var(--fw5);
  }
  .join-sec .join-sec-content h6{
    color:#fff;
}

  .join-sec .join-sec-content p{
      color:#fff;
      font-size: var(--fz16);
  }
  .join-sec .join-sec-content .sign-now a{
      padding: 10px 30px;
      background-color: #fff;
      border-radius: 30px;
      color:#000;
      font-weight: 600;
      letter-spacing: 1px;
      text-decoration: none;
      box-shadow:0px 4px 20px rgb(0 0 0 / 39%);
  }

  .deshboard-part {
    padding: 10px;
    border-radius: 10px;
    background-color: #16D489;
}



/*  .play-icon {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/


/* client pages css start */


.tab-content>.active {
    display: block;
}



.sec-1  input[type=text] {
    padding: 7px;
    font-size: var(--fz16);
    border:1px solid #16d489;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.search-container button {
    padding: 6px 10px;
    background: #16d489;
    font-size: 17px;
    color: var(--white);
    border: 1px solid #16d489 !important;
    cursor: pointer;
    margin-left: -4px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
  .search-container button:hover {
    background: var(--primary-color);
    border:1px solid #0a3570;
  }

  .search-add .add a{
     padding: 7px;
     background-color: #16d489;
     color:var(--white);
     text-decoration: none;
     border-radius: 30px;
  }
 





.sec-1-heading li:nth-child(1)::before {
    content: '';
    position: absolute;
    border-right: 1px solid #ffffff;
    width: 2px;
    height: calc(100% + 40%);
    top: -5px;
    right: -24px;
}
.sec-1 {
    padding: 5px;
    background-color: #f1f0f0;
}
.search-container input:focus{

    outline:  none !important;
}

.sec-1-heading {
    text-align: left !important;
}

.myaccount-sec{
    padding: 60px 0px;
}

.my-img img {
    border-radius: 50%;
    margin-right: 20px;
    width: 70px;
    height: 70px;
}
.my-content a {
    padding: 6px 40px;
    background-color: var(--primary-color);
    border-radius: 30px;
    box-shadow: 0px 7px 8px rgb(0 0 0 / 17%);
    color: var(--white);
    display: inline-block;
    text-decoration: none;
}
.deshborad-box > img{
    /* width: 250px !important;
    height: 250px;
    border-radius: 50%; */
    margin: auto;
}
.myaccount-sec .update a{
    padding: 7px 30px;
    background-color: #16d489;
    border-radius: 30px;
    color:var(--white);
    text-decoration: none;
    /* box-shadow:0px 7px 8px rgb(0 0 0 / 17%); */
}

.personal-box{
    background-color: #f3f2f2;
    padding: 50px;
    border-radius: 10px;
}
.personal-boxs{
    background-color: #f3f2f2;
    padding: 50px 10px;
    border-radius: 10px;
}

.myaccount-sec .form-group {
    padding: 10px 15px;
    margin-bottom: 1rem;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 30%);
}

.myaccount-sec .form-control {
    border: none;
    width: 100%;
    background: transparent;
    padding: 0px;
}

.myaccount-sec input:focus{
    box-shadow: none !important;
}

.password-sec .form-group {
    padding: 10px 15px;
    margin-bottom: 1rem;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 30%);
}

.password-sec .form-control {
    border: none;
    width: 100%;
    background: transparent;
    padding: 0px;
    font-size: 12px;
}

.password-sec input:focus{
    box-shadow: none !important;
}

.password-sec  .update a{
    padding: 7px 30px;
    background-color: #16d489;
    border-radius: 30px;
    color:var(--white);
    text-decoration: none;
    box-shadow:0px 7px 8px rgb(0 0 0 / 17%);
}


.password-sec{
    padding: 0px 0px 60px;
}

.password-sec .line{
    position: relative;
}



/* schedule section css start */

.schedule-sec{
    padding: 60px 0px;
}

.schedule-sec .theadbgcolor{
    background-color: var(--secondary-color);
}

.user-name img{
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.schedule-icon ul li a img{
    border-radius: 50%;
    width: 30px;
}

.youtube-name img {
    width: 50px;
}


.table-light>td, .table-light>th {
    background-color: #fdfdfe;
    vertical-align: middle;
}

.table td {
    vertical-align: middle !important;
    font-size: 16px;
    font-weight: 500;

}


.color-backgroundcolor .sketch-picker{
    position: absolute;
    left: 0;
    top: 38px;
    z-index: 99;
}


/* schudule details start */

.schedule-details-sec{
   padding: 0px 0px 40px;
}

.schedule-details-sec .theadbgcolor{
    background-color: var(--secondary-color);
}

.deshborad-sec .video-imgages ul li a{
   padding: 10px 30px;
   background-color: #16d489;
   border-radius: 30px;
   color:#fff;
   margin: 10px;
   text-decoration: none;
}

.deshborad-sec .video-imgages{
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.schedule-sec .my-content span {
    color: #bfc6cc;
}

.schedule-sec .details-box{
    background-color: #f0efef;
    padding: 20px;
    border-radius: 20px;
}

.schedule-sec .schedule-icon {
    display: inline-block;

}

.modal.show{
    /*overflow-y: scroll;*/
}

.modal.show .modal-dialog{
    max-width: 1130px;
}

.embed-responsive-1by1::before {
    padding-top: 57%;
}

.modal-content {
    max-width: 95%!important;
    margin: auto;

}

.schedule-sec .schedule-table {
    box-shadow: 0px 15px 20px rgb(0 0 0 / 19%);
}


.create_content_sec{
  padding: 60px 0px;
}


.create_content_sec .create_content_box  form  .form-group {
    padding: 5px;
    margin-bottom: 1rem;
    background: #fff;
border-radius:5px;

}

.create_content_sec .create_content_box  form .form-control {
    border: none;
    width: 100%;
    background: transparent;
    padding: 0px;

}

.files-stream h6 {
    text-align: left;
}


.create_content_sec .create_content_box  form input:focus{
    box-shadow: none !important;
}

.create_content_sec .create_content_box .creat-btn a{
    padding: 8px 25px;
    border-radius: 30px;
    background-color:#16d489 !important;
    color:var(--white);
    text-decoration: none !important;
}

.upload-btn-wrapper .custom-file-input{
    left: 0;
    top:0;
    opacity: 0;
}


.create_content_box{
    padding: 30px;
    border-radius: 20px;
    background-color: #ebebeb;
    text-align: left;
}


.play-icon img{
    height: inherit;
    margin: auto;
}


.create_content_box textarea:focus{
    box-shadow: none !important;
}

 h6.view-like-share-count{
   width: auto !important;
}

h6.view-like-share{
    width: auto !important;
}


.file-upload{
    cursor: pointer;
    opacity: 0;
    top: -82px;
    left: 179px;
    height: 40px;
    width: 131px;
    background: red;
    font-size: 0px;
    position: absolute;
    z-index: 1;
}


/* video creater css */




.text-position textarea {
    background-color: #bec4caba;
    color: #fff;
    border-radius: 0px;
}
.text-position textarea:focus{
    background-color: #bec4caba !important;
    box-shadow: none !important;
}

.text-position textarea.form-control{
    color:#000!important;
}

.textarea-box  ::placeholder{
    color:#000 !important;
}

.library-box .imagesizes{
    object-fit: cover;
    /* height: 10vh !important; */
}

.music-content .music-name{
    font-size: 13px;
}


.text-position .textarea-box label{
    font-size: 13px !important;
}

.text-position-dropdown label {
    font-size: 13px !important;
    margin-left: -13px;
}

.search-video div{
    width: 100%;
    height: 100%;
}
.search-video div div,.search-video div div img, .search-video div video{
    width: 100%;
    height: 100%;
}










.text-position-dropdown .box span{
    display: none;
}
.text-position-dropdown .box.top_left {
    border-top-left-radius: 15px;
}

.logo-position .box.top_left {
    border-top-left-radius: 15px;
}
.text-position-dropdown .box:hover{
    background-color: #16d489;
    transition: 0.2s all;
}

.logo-position .box:hover{
    background-color: #16d489;
    transition: 0.2s all;
}


.text-position-dropdown .box.top_right {
    border-top-right-radius: 15px;
}
.logo-position .box.top_right {
    border-top-right-radius: 15px;
}

.text-position-dropdown .box.bottom_left {
    border-bottom-left-radius: 15px;
}
.logo-position .box.bottom_left {
    border-bottom-left-radius: 15px;
}

.logo-position p{
    font-size: 12px;
    margin: 0px;
    margin-top: 5px;
}



.audio-library-box .card{
    /* padding-right: 5px !important; */
}
.audio-library-box .card{
    padding: 0px;
}

.audio-library-box{
    height: auto;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
}


.audio-library-box .card .card-body button.playaudio {
    background-color: #16d489;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    line-height: 25px;
    color: #fff;
    border: none;
    margin-left: 5px;
}


.load-more-btn button{
    padding: 10px 20px;
    background-color: #16d489;
    color:#fff;
    border-radius: 30px;
    border:none;
}




.genrate-play-save button{
  padding: 5px 15px;
  border:1px solid #000;
  border-radius: 30px;
  font-size: 13px;
  background-color: transparent !important;
}

.audio-library-box .border-left-design {
    border-left: 4px solid #181f28;
    height: 50px;
    float: left;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.audio-library-box .card .card-body button.btn-add-quote {
    background-color: #bec4ca;
    border-radius: 30px;
    border: none;
    color: #fff;
    font-size: 12px;
    padding: 0px 10px;
    transition: .2s ease-in-out;
}

.audio-library-box .card .card-body button:hover{
  background-color: #093470 !important;
  transition: .2s ease-in-out !important;
}

.genrate-play-save button:hover {
    color: #fff !important;
}


.logo-position .box.bottom_right {
    border-bottom-right-radius: 15px;
}

.text-position-dropdown .box {
    float: left;
    margin: 1px;
    border: 2px solid #ddd;
    border-radius: 0;
    background-color: #ddd;
    cursor: pointer;
    padding: 14px;
    width: 36px;
    height: 20px;
    color: #fff;

    transition: 0.2s all;
}



.logo-position .box {
    float: left;
    margin: 1px;
    border: 2px solid #ddd;
    border-radius: 0;
    background-color: #ddd;
    cursor: pointer;
    padding: 14px;
    width: 36px;
    height: 20px;
    color: #fff;
    -webkit-transition: 0.2s all;
    -moz-transition: 0.2s all;
    -ms-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
}

.youtube-content button:focus{
    box-shadow: none !important;
}

.slide-setting .form-control {
    border: none;
    border-radius: 4px;
    background-color: #bfc6cc;
    color: #fff;
    height: auto;
    padding: 1px;
    padding-left: 5px;
}

.slide-setting   input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
    margin: 0px;
    padding: 2px;
    background-color: #16d489 !important;
  }






.slide-setting .input-group-btn-vertical > .btn:first-child {
    border-top-right-radius: 4px;
}


.slide-setting .input-group-btn-vertical > .btn {
    display: block;
    float: none;
    margin-left: -17px;
    position: relative;
    border-radius: 2px;
    background-color: #16d489;
    padding: 3px;
    color: #fff;
    margin-top: 1px;
    height: 17px;
    line-height: 8px;
}


.slide-setting .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 2%;
    vertical-align: middle;
    display: table-cell;
}



.font-style-box label{
    font-size: 13px;
}


.opacity-font{
    font-size: 13px;
}



.upload-box-video{
    padding: 10px 0px;
}

.upload-box-video .custom-file-label {
    border-radius: 30px;
    width: 100%;
    height: 21vh;
    background-color: #000;
    top: -16px;
    left: 0%;
    opacity: 0;
    cursor: pointer;
}
.upload-box-video .custom-file{
    position: absolute;
    left: 0% !important;
    width: 100%;
    height: 100% !important;
}

.upload-border{
    border:2px dashed #16d489 !important;
    border-radius: 30px;
}

.blur-removetext .form-check{
    padding: 0px !important;
}

.blur-removetext .form-check-input {
    opacity: 1 !important;
}

.blur-removetext .form-check-label{
    font-size: 12px;
}

.color-backgroundcolor p{
    font-size: 13px;
}

.library-box .input-group-btn .btn-default {
    z-index: 9;
    /* margin-left: -40px; */
    background-color: #354358;
    border: none;
    /* position: relative; */
    /* left: -30%; */
    padding: 7px 22px;
    color: #fff;
    border-radius: 2em;
}
.quotewidth input, .library-box input{
    border-radius: 2rem !important;
}
.library-box .input-group-btn{
    position: absolute;
    right: 0;
}

input:focus{
    box-shadow: none !important;
}


.box-upload{
    padding: 10px;
    border:2px dashed #16d489;
    border-radius: 30px;
}




.library-box input{
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
}

/* video creater css end */












.drag-btn{
    position: relative;
}
.drag-btn a {
    padding: 10px 30px;
    background-color: #16d489;
    color: var(--white);
    border-radius: 30px;
    text-decoration: none;
    position: absolute;
    transform: translate(-63px, -83px);
}

.create_content_file_upload{
    padding: 30px;
    background-color: #f0efef;
    border-radius: 20px;
}

.progress{
    background-color: #ffffff;
    height: 10px;
}

.drop-content span{
    color:#3e99f3;
    display: inline-block;
    margin-top:10px;
}

.drop-content img {
    width: 50px;
}

.file-stream-sec{
    padding: 30px 0px;
}

.file-stream-sec .theadbgcolor{
    background-color: var(--secondary-color);
}

.create_content_box .tagstyle li{
    padding: 1px 20px;
   border-radius: 20px;
   background-color: #bfc6cc;
   font-size: 14px;
   margin-top: 10px;
   color:var(--white);
}

.login-sec{
    padding: 93px 0px;
    background: linear-gradient(to bottom right, #0a3570 0%, #0a7d5c 100%);
    width: 100%;
    height: calc(100vh - 54px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-account form{
    background-color: #ffffff;
    padding: 30px 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}

.login-account h5{
    color:#16d489;
    font-size: 24px;
    font-weight: 500;
}

.login-account label{
    font-size: 18px;
    font-weight: 500;
}

.login-account input{
    border:none !important;
    border-bottom: 1px solid #bfc6cc !important;
    border-radius: 0px !important;
}

.login-account input:focus{
    box-shadow: none !important;
}
.login-account button {
    padding: 10px 30px;
    border-radius: 30px;
    color: var(--white);
    background-color: #16d489;
    border: none;
    display: block;
    margin: auto;
}
.login-account .forget-sec h6 span{
    color:#16d489;
}

.logo-img-line{
    border-bottom: 2px solid #15718c;
}

.logo-head.text-white h5 {
    font-size: 22px;
    font-weight: 400;
    line-height: 1.6;
    margin-top:20px;
}


.logo-img-line {
    border-bottom: 2px solid #15718c;
    padding-bottom: 20px;
}
/*create video css start */


.save-next ul li{
    padding: 5px 30px;
    border-radius: 30px;
    margin: 5px;
}

.save-next ul li:nth-child(1){
    background-color: #bfc6cc;
}

.save-next ul li:nth-child(2){
    background-color: #16d489;
    margin-left: 20px;
}

.save-next ul li a{
    color:var(--white);
    text-decoration: none;
}



.create-video-sec {
    padding: 20px 0px 20px;
}

/* .create_video_content span {
    line-height: 50px;
    display: inline-block;
    background:#bfc6cc;
    color: #fff;
    width: 200px;
    height: 50px;
    clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
    margin: -10px;
} */


.create_video_content .nav .nav-item .nav-link {
    line-height: 36px;
    display: inline-block;
    background: #bfc6cc;
    color: #fff;
    width: 200px;
    height: 50px;
    clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
    margin: -6px;
    border-radius: 0px !important;
}

.create_video_content .nav .nav-item .nav-link.active{
    line-height: 36px;
    display: inline-block;
    background: #16d489;
    color: #fff;
    width: 200px;
    height: 50px;
    clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
    margin: -6px;
    border-radius: 0px !important;
}


.nav-pills .nav-link.active{
    background-color: #16d489;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
}

.nav-pills .nav-link{
    background-color: #bec4ca;
    margin: 10px 0px;
    color:var(--white);
    border-radius: 30px;
    font-size: 13px;

}

.search-tab {
    box-shadow: 0px 3px 5px rgb(0 0 0 / 17%);
}
.search-tab input{
    border:0px !important;
    background-color: var(--white);
    border-radius: 5px;
}
.search-tab .input-group-text {
    border: none !important;
    background-color: #16d489 !important;
    color: var(--white);
    clip-path: none;
    padding: 0px;
    margin: 0px !important;
    width: 50px;
}

.join-vidoes{
    background-color: var(--secondary-color);
    border-radius: 20px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 35%);
    width: 100%;
    height: 300px;
    position: relative;
    margin: auto;
    overflow: hidden;
}


.logowatermark-icon .delete-icon{
     display: none !important;
     transition: .3s ease-in-out;
}
.logowatermark-icon:hover .delete-icon{
    display: block !important;
    transition: .3s ease-in-out;
}

.water-mark-delete-icon .delete-icon{
    display: none !important;
     transition: .3s ease-in-out;
}
.water-mark-delete-icon:hover .delete-icon{
    display: block !important;
    transition: .3s ease-in-out !important;
}


.trands-action-list ul li img{
    width: 30px;
    height: 30px;
}

.twitter-item-box li span{
    margin-right: 5px;
}


.twitter-item-box li {
    background-color: #fff;
    padding: 7px;
    border-radius: 5px;
    margin: 5px;
    box-shadow: 0px 0px 5px;
}



/*.join-vidoes .play-icon {*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*}*/

.search-tab {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 17%);
    margin: 20px;
}



.list-vidoes {
    background-color: #f1f0f0;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 35%);
    width: 100%;
    height: auto;
    position: relative;
    margin: auto;
}

.list-vidoes .tilesvideosize img{
    width: 100%;
}

.create-video-sec .linebar{
    position: relative !important;
}
.create-video-sec .linebar::before{
    position: absolute;
    content: '';
    width: 5px;
    height: 50px;
    background-color: #16d489;
    left: 0;
    top:15px;
}

.plus-icon a {
    color: #f1f0f0;
    font-size: 30px;
}


.list-vidoes .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-vidoes .play-icon img{
    max-width: 100%;
    margin: auto;
    display: flex;
}



.font-style-box .wrapper .menu .divider {
    height: 1px;
    
    overflow: hidden;
    background-color: #e5e5e5;
}

.font-style-box .wrapper .menu li a{
    color:#333333;
    text-decoration: none;
}


.font-style-box .wrapper {
    max-width: 600px;
  }
  .font-style-box .dropdownbox {
 
    width: 270px;
    background: #fff;
    border: 1px solid #d0d4d8;
    cursor: pointer;
}
 .font-style-box .dropdownbox > p {
    padding: 5px 10px;
    font-size: 14px;
    user-select: none;
    -moz-user-select:none;
    /* Safari */
    -khtml-user-select: none;
  }
  
 .font-style-box  ul.menu {
    position: absolute;
    margin: 0 auto;
    width: 270px;
    overflow: hidden;
    height: 0;
    margin-top: 10px;    
          transition: all 0.3s ease-in;
    /*-moz-transform:scale(0); */
    background: #fff;
    color: #000;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 17%);
    cursor: pointer;
    user-select: none;
    -moz-user-select:none;
    -khtml-user-select: none;
    z-index: 999;
  }
  
  .font-style-box ul.menu li {
    padding: 2px 10px;
    font-size: 14px;
    transition: all 0.3s ease-in;
    border-bottom: 1px dotted rgb(237, 254, 223);
  }
.font-style-box   ul.menu li:hover {
    padding-left: 20px;
    color: #000;
    background: rgb(151, 248, 175);
  }


  .tag-box #mainTooltip{
      width: 100px !important;
  }

  


.create-video-list.mt-5 {
    background: #bfc6cc;
    padding: 30px;
    padding-bottom: 10px;
}


.search-tab input:focus{
  box-shadow: none !important;
}

/* stream css start */

.mdb-select.md-form {
    border: none;
    border-radius: 5px;
    width: 200px;
    height: 40px;
}

.mdb-select.md-form select{
    border-color: none !important;
    box-shadow: none !important;
}

.tags-content ul span{

    display: inline-block;
    margin-left: 5px;
}

.stream-box {
    padding: 20px 40px;
    background: var(--white);
    border-radius: 10px;
    box-shadow: 0px 0px 60px rgb(0 0 0 / 6%);
    position: relative;
}

.stream-img .imgsize {
    max-width: 70% !important;
}

.stream{
    padding: 0px 0px 40px;
}
 .editimg img {
    width: 40px;
}

.stream .theadbgcolor{
    background-color: var(--secondary-color) !important;
}

.trands-action-list ul li{
    padding: 5px;
}

.trands-action-list {
    width: 150px;
    margin: auto;
}

.tranding-post-sec .trands-table-box h6{
   width: 150px;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space: nowrap;
   margin: auto;
}


.custom-delete-btn a span{
    width: 40px;
    height: 40px;
    display: inline-block;
    line-height: 40px;
    background-color: #f5444c;
    color:#fff;
    border-radius: 50%;
}

.custom-edit-btn a span{
    width: 40px;
    height: 40px;
    display: inline-block;
    line-height: 40px;
    background-color: #3e99f3;
    color:#fff;
    border-radius: 50%;
}

.trands-table-box .table td, .table th{
    vertical-align: middle;
}

/* popup css start */


.popup-sec{
    padding: 80px 0px;
}


.icon-pop span img {
    max-width: 70px;
    height: 60px;
}



/* .project-data ul li:hover {
    overflow: visible;
} */


.user-name h6 {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.icon-pop.text-center h6 {

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;

}

.modal-header button:focus{
    outline: none !important;
}


.icon-pop {
    margin: 10px !important;
    height: min-content;
}

.project-data ul li{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.deshborad-sec  .project-data  h6{

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
    margin: auto;
}


.loader {
    width: 50px;
    height: 50px;
    display: inline-block;
    padding: 0px;
    opacity: 0.5;
    border: 3px solid #16d489;
    animation: loader 1s ease-in-out infinite alternate;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



.loader:before {
    content: " ";
    position: absolute;
    z-index: -1;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border: 3px solid #16d489;
  }

.loader:after {
    content: " ";
    position: absolute;
    z-index: -1;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border: 3px solid #16d489;
  }



.loaderbox {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #00000061;
    display: block;
    top: 0;
    left: 0;
}


  @keyframes loader {
     from {transform: rotate(0deg) scale(1,1);border-radius:0px;}
     to {transform: rotate(360deg) scale(0, 0);border-radius:50px;}
  }
  @-webkit-keyframes loader {
     from {-webkit-transform: rotate(0deg) scale(1, 1);border-radius:0px;}
     to {-webkit-transform: rotate(360deg) scale(0,0 );border-radius:50px;}
  }





.strem-title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  
}

.strem-tag{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.strem-description{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}


.youtube-name h6 {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


.popup-sec .nav .nav-item{
    line-height: 40px;
    display: inline-block;
    background:#bfc6cc;
    color: #fff;
    width: 200px;
    height: 50px;
    clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
    margin: -9px;

}

.popup-sec .nav .nav-item .nav-link.active{
    background-color: var(--secondary-color);
}

.back-next-btn ul li:nth-child(1) a{
    background-color: #bfc6cc;
    border-radius: 30px;
    margin-right: 20px;
    padding: 5px 30px;
    text-decoration: none;
    color:var(--white) !important;
}

.back-next-btn ul li:nth-child(2) a{
    background-color: #16d489;
    border-radius: 30px;
    margin-right: 20px;
    padding: 5px 30px;
    text-decoration: none;
    color:var(--white) !important;
}


/* popup css end */


/* training section start */


.training-sec{
    padding: 60px 0px;
    background-color: #f3f2f2;
}

.training-content h5{
    font-size: 24px;
    font-weight: 600;
}

.training-content .input-group{
    width: 80%;
    border-radius: 5px;
}
.training-content .input-group input{
    background-color: var(--white);
    border:none !important;
}
.training-content .input-group .input-group-text{
    background-color: #16d489;
    color:var(--white);
    border:none !important;
    border-radius: 5px;
    cursor: pointer;
}

.training-content .input-group input:focus{
    box-shadow: none;
}





.training-important-sec{
    padding: 60px 0px;
}


.training-box{
    width: 100px;
    height: 100px;
    background-color: var(--secondary-color);
    border-radius: 50%;
    transition: .4s ease-in-out;
}
.training-box img {
    width: 45px;
}

.training-box:hover{
    background-color: var(--primary-color);
    transform: scale(0.9);
    transition: .4s ease-in-out;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.5);
}

.training-important-head h4{
font-size:30px;
color:#384653;
}



/* training section end */

/* popular section start */

.popular-sec{
    padding: 60px 0px;
    background-color: #f3f2f2;
}

.popular-sec .join-vidoes{
    width: 100%;
    transition: .4s ease-in-out;
}

.join-vidoes.hoverbg:hover {
    background:var(--primary-color);
    transition: .4s ease-in-out;
}


.training-img{
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
    position: relative;
}

@keyframes  up-down{
    0%{
        transform: translate(10px);

    }

    100%{
        transform: translate(-10px);
    }
}



/* popular section end */


.content-sec-videoreel {
    padding: 60px 0px 60px;
}

.content-sec-videoreel .contact-videoreel a{
    padding: 10px 30px;
    border-radius: 30px;
    color:var(--white);
    background-color: #16d489;
    text-decoration: none;
    box-shadow: 1px 10px 29px rgb(0 0 0 / 33%);
}


/* gdpr css start */

.gdpr-para span{
   color:#f27277;
}



/* gdpr css end &/


/& client Account css start */

.team-member-sec{
    padding: 0px 0px 60px;
}

.client-account{
    padding: 60px 0px;
    text-align: center;
}

/* client Account css end */


/* notification sec start */

.notification-sec{
    padding: 60px 0px;
}

.notifiction-box{
  border:4px solid #a4d166;
  padding: 30px;
  border-radius: 30px;
}


.notifiction-box-error{
    border:4px solid #f5444c;
    padding: 30px;
    border-radius: 30px;
}
.success-head h5 {
    margin: 0;
    color: #a4d166;
    font-size: 24px;
    font-weight: 600;
}

.success-head .error{
    color:#f5444c;
}
.success-head .info{
    color:#539fff;
}



.notifiction-box-messages{
    border:4px solid #539fff;
    padding: 30px;
    border-radius: 30px;
}

/* notification sec end */


/* upgrades section start */

.upgrades-sec{
    padding: 60px 0px 60px;
}

.upgrades-sec #v-pills-tabContent {
    border-radius: 15px;
    padding :20px;
    background: #edecec;

}

.upgrades-sec .fb-card{
    background-color: #093470;
    color:#fff;
}

.upgrades-sec .welcome-box a {
    background: #16d489;
    text-decoration: none !important;
    margin: 2px;
    padding: 5px 10px;
}

.welcome-head{
  background-color: var(--primary-color);
  padding: 10px 0px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.welcome-para{
    background-color: var(--white);
    padding: 50px 10px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}



.upgrades-sec .nav-pills .nav-link.active{
    background-color: var(--primary-color) !important;
    color:var(--white);
    padding: 10px;
    margin: 0px;
}


.upgrades-sec .nav-pills .nav-link {
    background-color: #edecec;
    color: #384653;
    border-radius: 0px !important;
   border-bottom: 1px solid #f1f0f0;
   padding: 10px;
   margin: 0px;

}

.upgrades-sec #v-pills-tab {
    background: #edecec;
    border-radius: 10px !important;
}

.upgrades-sec .nav{
    border-radius: 10px !important;
    padding-top: 10px;
    padding-bottom: 10px;
}
/* upgrades section end */



/* training internal page css start */


.videoreel-tutorial-sec {
    padding: 60px 0px;
    min-height: 100vh;
}

.videoreel-tutorial-sec .nav span{
    float: right;
}

.videoreel-tutorial-sec .nav{
    background-color: #edecec;
     border-radius: 10px;
    padding: 10px 0px;
}



.videoreel-tutorial-sec  .nav-pills .nav-link.active{
  background-color: var(--primary-color) !important;
    box-shadow: none !important;
    color:var(--white);
}

.videoreel-tutorial-sec .nav h5{
    padding:15px 10px;

}

.videoreel-tutorial-sec  .nav-pills .nav-link{
    margin: 0px !important;
    color: #384653;
    border-radius: 0px !important;
    background-color: #edecec !important;
    transition: .4s ease-in-out;
}

.navbar-nav .dropdown  a[aria-expanded="true"]{
    color: #646464 !important;
    background: #F1F1F1;
    border-radius: 30px;
    padding: 4px 10px;
}




.videoreel-tutorial-sec  .nav-pills .nav-link:hover{
    background: var(--primary-color) !important;
    color:#fff;
    transition: .4s ease-in-out;
}

.videoreel-tutorial-sec  .nav-pills .nav-link.active{
    background: var(--primary-color) !important;
}

 .sec-1-heading h5 {
    font-size: 18px;
    padding: 10px 0px;
}

.imgcreator-sec{
    padding: 60px 0px 60px;
}

.imgcreator-sec .creator-head .form-group{
    width: 100%;
    margin: 0px !important;
}


/* creator css start */

.creator-icon ul li+li{
    margin-left: 10px;
}

.creator-download ul li+li{
    margin-left: 15px;
}

.creator-download ul li span{
    margin-right: 5px;
}
.creator-download ul li span img{
   width: 10px;
}


.creator-download ul li a {
    color: #aaa7a7;
    text-decoration: none;
    font-size: 11px;
}


.creator-box {
    background-color: #ececec;
    padding: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.imgcreator-sec .nav a {
    background: #3e99f3;
    border-radius: 0px;
    text-align: center;
    padding: 10px 9px;
    color: #ffffff;
    border: none !important;
    font-size: 14px;
}

.creator-icon ul li a img {
    width: 12px;
    margin-right: 10px;
}
.creator-icon ul li {
    border-right: 1px solid #868d94;
    padding-left: 10px;
    height: 14px;
    display: flex;
    align-items: center;
}

.creator-icon ul li:nth-last-child(1){
    border-right: 0px !important;
}

.imgcreator-sec .nav a h6 {
    margin-top: 10px !important;
    font-size: 11px;
    letter-spacing: 1px;
}

.imgcreator-sec .nav-tabs {
    border-bottom: none !important;
    background-color: var(--secondary-color);
    border-top-right-radius: 20px;
    overflow-x: auto;
    flex-wrap: nowrap;
}





.imgcreator-sec .nav-tabs .nav-link.active{
    background-color: var(--primary-color);
    color:var(--white);

}


#laryer-options .parent-tab{
    height: 450px !important;
    overflow:auto !important;
    overflow-x: hidden !important;
}



.height{
    background-color: #cccccc;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

}

.imgcreator-sec .nav a:nth-last-child(1) {
    border-top-right-radius: 20px !important;

}


.imgcreator-sec .nav a img {
    width: 17px;
}

.imgcreator-sec .nav-tabs .nav-item {
    margin-bottom: 0px !important;
}

.creator-inputtype input:focus{
    box-shadow: none !important;
}
.form-row select:focus{
    box-shadow: none !important;
}


.noti-worng img:hover {
    cursor: pointer;
}





.nav-img-content .nav-pills .nav-link.active{
   border-radius: 0px !important;
   background-color: transparent;
   box-shadow: none !important;
   color:var(--primary-color);
 }

 .nav-img-content .nav .nav-link{
    border-radius: 0px !important;
    background-color: transparent;
    margin: 10px;
  }


  .has-search{
      position: relative;
  }


  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    color: #aaa;
    right: 12px;
    top: 10px;
}

.has-search input:focus{
    box-shadow: none;
}

.creator-icon .downsize img{
   width: 20px;
}

.img-upload-input {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
    z-index: 9;
    
}

.creator-download ul li a:hover {
    color: var(-);
}

.shape-color .input-group {
    width: 50%;
}

.shape-box .shape-item a {
    width: 46px;
    height: 46px;
    background-color: #000;
    display: inline-block;
    margin: 10px;
}
.shape-box .shape-item2 a {
    width: 46px;
    height: 46px;
    background-color: #000;
    display: inline-block;
    margin: 10px;
    border-radius: 5px;
}

.shape-box .shape-item3 a {
    width: 66px;
    height: 46px;
    background-color: #000;
    display: inline-block;
    margin: 10px;

}

.shape-box .shape-item-border a {
    width: 46px;
    height: 46px;
    border:2px solid #000;
    display: inline-block;
    margin: 10px;
}
.shape-box .shape-item2-border a {
    width: 46px;
    height: 46px;
    border:2px solid #000;
    display: inline-block;
    margin: 10px;
    border-radius: 5px;
}

.shape-box .shape-item3-border a {
    width: 66px;
    height: 46px;
    border:2px solid #000;
    display: inline-block;
    margin: 10px;

}




















.shape-box .shape-item .cliped-circle{
    width: 55px;
    height: 55px;
    background-color: #000;
    border-radius: 50%;
    position: relative;
    z-index: 999;
}
.shape-box .shape-item .cliped-circle::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    bottom: 0;
    background-color: #ccc;
    width: 49px;
    height: 49px;
    border-radius: 50%;
}



.shape-box .shape-item .cliped-triangle{
    background-color: #000;
    width: 61px;
    height: 53px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    position: relative;
}

.shape-box .shape-item .cliped-triangle::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 6px;
    right: 0;
    bottom: 0;
    width: 49px;
    height: 45px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: #ccc;
}





.shape-box .shape-item .cliped-pentagon{
    background-color: #000;
    width: 55px;
    height: 50px;
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
    transform: rotate(-21deg);
    position: relative;
}


.shape-box .shape-item .cliped-pentagon::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 7px;
    width: 45px;
    height: 43px;
    -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
    transform: rotate(
73deg
);
    background-color: #ccc;
}


.shape-box .shape-item .cliped-hexagon{
    width: 55px;
    height: 55px;
    background-color: #000;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    position: relative;
}


.shape-box .shape-item .cliped-hexagon::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 3px;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 48px;
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    background-color: #ccc;
}







.shape-box .shape-item .circle{
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background-color: #000;
}

.shape-box .shape-item .triangle{
    background-color: #000;
    width: 61px;
    height: 53px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.shape-box .shape-item .pentagon{
   background-color: #000;
   width: 55px;
   height: 50px;
   clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
   transform: rotate(-21deg);
}

.shape-box .shape-item .hexagon{
    background-color: #000;
    width: 55px;
    height: 55px;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);

}



.border-content .input-group {
    width: 45%;
}

.border-style {
    display: block;
    min-height: 50px;
    margin-bottom: 45px;
    color: #222222;
}

.solid-border {
    border: 3px solid #222222;
}
.dashed-border-light {
    border: 2px dashed #222222;
}
.dotted-border {
    border: 3px dotted #222222;
}

.dashed-border {
    border: 3px dashed #222222;
}
.uplaod-img {
    background-color: var(--primary-color);
    display: block;
    width: 100%;
    padding: 11px 70px;
    color: #fff;
    font-size: 14px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    transition: .4s ease-in-out;
    position: relative;
    text-align: center;
}

.uplaod-img:hover{
    background-color: transparent;
    transition: .4s ease-in-out;
    border:1px solid #868d94;

}


.align-loader {
    display: block;
    justify-content: center;
}


.bgcolor{
    font-size: 16px;
}

a.text-item.item-1 {
    font-size: 35px;
    line-height: 45px;
    font-weight: 600;
    text-decoration: none;
}

a.text-item.item{
   padding: 30px 0px;
   display: block;
   border-bottom: 1px solid #868d94;
   color:#222;
   text-align: center;
}

#text-styles {
    padding: 20px;
    position: absolute;
    top: 67px;
    left: -100%;
    right: 0;
    width: 100%;
    background-color: #ccc;
    z-index: 9;
    transition: .2s ease-in-out;
    display: none;
}
#text-realative{
    position: relative;
}

.general-box input:focus{
    box-shadow: none;
}
.general-box select:focus{
    box-shadow: none;
}
.creator-download ul li a:hover {
    color: var(--primary-color);
}

#extra-tab-pane p b {
    margin-right: 20px;
}

.btn-secondary{
    background-color: #EEEEEE;
    font-size: 12px;
    padding: 10px 15px;
    margin-left: -1px;
    color:#212529;
}

.btn-secondary1{
    background-color: #EEEEEE;
    font-size: 18px;
    padding: 15px 30px;
    margin-left: -1px;
    color:#212529;
}

.btn-group button{
    border:1px solid rgba(161, 161, 161, 0.8);
}


.btn-secondary:hover {
    color: #fff;
    background-color: var(--primary-color);
    border-color: #545b62;
}

.cinema-tab .nav .nav-link{
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 14px;
    margin: 0px !important;

}
.cinema-tab .nav .nav-link.active{
   color:var(--primary-color);

}

.icon-items .add-icon {
    font-size: 60px;
    margin: 5px;
}

.aad-layer .laryer{
    width: 100%;
    height: 40px;
    background-color: #000;
}

.logo-box{
    position: relative;
}

.logo-box .custom-file-input {
    border-radius: 30px;
    width: 100%;
    background-color: #000;
    opacity: 0;
    cursor: pointer;
    font-size: 0px;
    left: 0%;
    top: -18px;
    height: 18vh;

}



.search-add .search-container form input{
    width: 80% !important;
}


.fc-daygrid-day-events  .fc-daygrid-event{
    white-space:pre-wrap !important;
}


.logo-box .custom-file-label{
    display: none;
}

.logo-box .custom-file{
    position: absolute;
    left: 32px;
    width: 86%;
}

.trands-action-list  ul li #mainTooltip{
   width: 100px !important;
}

.infinite-scroll-component{
    overflow:  unset !important;
}


.font-style-box .menu.showMenu{
    height: 40vh;
    overflow-y: scroll;
}


.logomark-img-size img{
    width: 100px !important;
}

.water-mark-size img{
    opacity: 0.9 !important;
}

.playicon-tooltip  #mainTooltip{
    width: 130px !important;
}

.deshborad-box .overlay .badge-primary {
    color: #000;
    background-color: #fff;
    position: absolute;
    right: 16%;
    top: 7%;
}

.uplaod-size-img h6{
    font-size: 12px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.create_content_file_upload .drag-drop {
    position: relative;
}

.create_content_box select:focus{
   box-shadow: none !important;
}


 .main-text-display{
    position: absolute !important;
    bottom: 0 !important;
    padding: 8px !important;
    font-size: 12px !important;
    color: #fff !important;

 }

.join-vidoes .main-text-display span {
    width: 100% !important;
    line-height: 1.5 !important;
}


.deshborad-box .overlay .badge-pill{
    color: #000;
    position: absolute;
    left: 20%;
    top: 7%;
}

.client-img-opacity{
    opacity: 0.5 !important;
}


.proj-img{
    min-height: 180px;
    max-height: 180px;
    margin: 0 auto;
}

.proj-img {
    width: 100%;
    object-fit: cover;
}




@media (min-width: 1200px){
.container{
    max-width: 1200px;
}

}
@media (max-width: 991px){
    .login-sec{
        height: calc(100vh - 166px);
    }
    .creator-box,.height{
        border-radius: 20px !important;
    }
    .myaccount-sec{
        padding: 0 !important;
        margin-bottom: 40px;
        margin-top: 20px;
    }
    .team-member-sec{
        padding: 0 !important;
    }
    .new-box-wrapper{
        padding: 0 10px;
    }
}
@media (max-width: 767px){
    .myaccount-sec{
        padding: 0 !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .myaccount-sec {
        padding: 40px 0;
    }
    .password-sec{
        padding: 0px !important;
        margin-bottom: 20px;
    }
}
@media (max-width: 576px){
    .login-sec{
        min-height: 84vh;
        height: 100%;
        padding: 30px 0 !important;
    }
    .myaccount-sec {
        padding:20px 0;
    }
    
}
    



.navmenuid .text h6{
    font-size: 12px;
    color: #057EDD;
}

.navmenuid span{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-block;
}
.navmenuid .text {
    color: #000;
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 130px;
}

.navmenuid {
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-part{
  padding: 60px 0px;
}

.banner-part-heading span{
    font-size: 18px;
    color:#0075EE;
}

.banner-part-heading h4{
    font-size: 30px;
    font-family: var(--Poppins);
}
.banner-part-heading p{
    font-size: 18px;
    color:#535353;
}

@media (max-width: 767px){
    .personal-boxs{
        padding: 10px 10px !important;
    }
 }