
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {

    /* Color Variables */
    --primary: #f5c400;
    --secondary: #f5d700;
    --background-color: #f5f5f5;
    --text-color: #333333;
    --solid-white: #FFFFFF;
    --border-white: #e5e5e5;
    --input-color: #FFFFFF29;
    --input-bg: #F5F5F5;
    --input-br: #b8b8b8;
    --table-bg: #e1e1e1cc;
    --table-text: #1A192E;
    --font-family: "Poppins", sans-serif;


}

* {
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
label,
input,
button,
span,
li,
th,
td,
select,
div {
    font-family: var(--font-family);
}


.whiteLabel_wrapper {
    padding: 45px 10px;
}

.white-block {
    background: var(--solid-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #e5e5e5;
    padding: 16px;
    margin-top: 25px;
    border-radius: 12px;
}

.White_label_wrap {
    padding-inline: 60px;
}

.white_label_profile {
    display: flex;
    justify-content: space-between;
}

.white-outer {
    background: #d9d9d9;
    color: var(--input-color);
    padding: 2px;
    border-radius: 8px;
    position: relative;
}

.white_inp {
    width: 100%;
    background: var(--input-bg);
    border-radius: 7px;
    border: none;
    color: var(--text-color);
    font-size: 15px;
    font-weight: 400;
    padding: 10px 16px;
}

.white-input-wrap {
    margin-top: 20px;
}

.white-input-wrap label {
    font-size: 15px;
    padding-bottom: 12px;
    display: block;
    font-weight: 600;
    color: var(--table-text);
}

.White_label_wrap .white-titleBar-left h2 {
    color: var(--solid-white) !important;
}

.white-file-upload {
    height: 200px;
    border: 1px dashed var(--input-br);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: var(--table-bg);
}

.white-file-upload input {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.white-swatch-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.white-sketch-picker .sketch-picker {
    position: absolute;
    z-index: 999;
}

.white-theme-btn {
    display: inline-block;
    border: none;
    cursor: pointer;
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    border-radius: 50px;
    font-size: 15px;
    font-weight: 600;
    color: var(--solid-white);
    padding: 8px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.white-theme-btn:hover {
    opacity: 0.7;
}

.white-list-item {
    background: #f7f7f7;
    padding: 20px;
    border-radius: 15px;
    list-style: none;
}

.white-list-item li {
    position: relative;
    margin-top: 10px;
    padding: 0 0 0 18px;
    color: var(--text-color);
}

.white-file-upload p {
    color: var(--text-color);
}

.white-list-item li::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #000;
    position: absolute;
    left: 0;
    top: 6px;
}

.white-item {
    margin-bottom: 10px;
}

.nav-pills .nav-link.white-link {
    background: #cdcdcd;
    color: var(--solid-white);
    border-radius: 50px;
}

.nav-pills .nav-link.white-link.active {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    color: var(--solid-white);
    border-radius: 50px;
}

.white_Customization_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.white-titleBar-left h2 {
    font-size: 22px;
    font-weight: 500;
    color: var(--solid-white);
}

.white-titleBar-left p {
    opacity: 0.7;
}

.white-titleBar-left h2 {
    color: var(--table-text);
}

.white-table tr th {
    background: var(--table-bg);
}

.white-table tr th,
.white-table tr td {
    padding: 8px 10px;
}

.white-table tr td {
    opacity: 0.7;
    font-weight: 400;
}

.white-table-icon {
    cursor: pointer;
    margin: 0 3px !important;
    padding: 0 !important;
    font-size: 20px;
}

.white_label_modal .modal-dialog {
    max-width: 700px;
}

.white_Label_modal_wrapper {
    padding: 10px;
}

.white_label_modal .modal-content {
    background: var(--input-color);
    border-radius: 15px;
    background: linear-gradient(var(--solid-white), var(--solid-white)) padding-box, linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) border-box;
    border: 1px solid transparent;
}

.white_modalClose {
    font-size: 22px;
    cursor: pointer;
    color: var(--text-color);
}


.white_label_head h2 {
    font-size: 25px;
    padding-bottom: 10px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
}

.white_label_side_bg {
    background: var(--table-bg);
    padding: 20px 22px;
    border-radius: 15px;
}

.white_modalIcon {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--table-bg);
    font-size: 32px;
    margin: 0 auto;
}

.white_label_integration {
    display: flex;
    justify-content: space-between;
}

.white_label_left_integration {
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    display: flex;
}

.white_label_integration_text {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.white_label_integration_text h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}

.white_label_integration_text p {
    font-size: 13px;
    opacity: 0.7;
    padding: 0;
    margin-bottom: 0;
}

.white-table-responsive {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
}

@media (max-width: 1200px) {
    .White_label_wrap {
        padding-inline: 0;
    }
}

@media (max-width: 576px) {
    .white_Customization_btn {
        flex-direction: column;
        align-items: self-start;
    }

    .white-titleBar-left h2 {
        font-size: 20px;
    }

    .white_label_integration {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
}